import Api from '../Api';

interface ChatAvailability {
  online: boolean;
  averageWaitingTime: string; // seconds -> returned as string by API
}

export interface MailValues {
  topic: string,
  subTopic?: number | string,
  name: string,
  email: string,
  body: string
  subject: string,
  orderNumber: string,
}

class HelpCenterService extends Api {
  getChatAvailability = async () => {
    const storeKey = process.env.GATSBY_STORE || 'drukwerkdeal-nl';

    const params = new URLSearchParams();
    params.set('storeKey', storeKey);
    const { data } = await this.httpClient.get<ChatAvailability>(`/chatOnline?${params}`);
    return Promise.resolve(data);
  };

  /**
   * Send a mail to customer care
   */
  async sendMail({
    topic, subTopic, name, email, body, subject, orderNumber,
  }: MailValues, origin: string, locale: string, store: string) {
    await this.httpClient.post('/send-mail', {
      formData: {
        topic,
        subTopic: typeof subTopic === 'string' ? parseInt(subTopic, 10) : subTopic,
        name,
        email,
        body,
        subject,
        orderNumber,
      },
      origin,
      locale,
      store,
    });
  }
}

export { HelpCenterService };

import React, {
  useMemo, useState, useEffect,
} from 'react';

import ChatContext from './ChatContext';

import { ChatStates, ChatIdentity, ChatConfig } from './chatTypes';

import useChatAvailability from '../../../hooks/query/useChatAvailability';
import ClientOnly from '../../ClientOnly';
import ChatButton from './ChatButton';

interface Props {
  locale: string;
  locationHref: string;
  locationPathName: string;
  identity: ChatIdentity;
  chatConfig: ChatConfig;
}

const ChatProvider = ({
  locale,
  locationHref,
  locationPathName,
  identity,
  chatConfig,
}: Props) => {
  const chatAvailability = useChatAvailability(chatConfig.enabled);
  const [chatStatus, setChatStatus] = useState<ChatStates>('initial');

  useEffect(() => {
    // Set ChatStatus to second or third state based on config and availability
    if (chatStatus !== 'unavailable'
      && (!chatConfig.zendeskKey || !chatConfig.channel)) {
      setChatStatus('unavailable');
    } else if (chatStatus === 'initial'
      && chatConfig.enabled
      && (chatAvailability.online || chatConfig.hasKnowledgeBase)) {
      setChatStatus('waiting');
    }
  }, [chatStatus, chatConfig, chatAvailability.online]);

  const ChatContextContent = useMemo(() => ({
    chatStatus,
    setChatStatus,
    chatConfig,
    locale,
    locationHref,
    locationPathName,
    identity,
    chatAvailability,
  }), [
    chatStatus,
    chatConfig,
    locale,
    locationHref,
    locationPathName,
    identity,
    chatAvailability,
  ]);

  return (
    <ChatContext.Provider value={ChatContextContent}>
      { chatConfig.enabled && (
        <ClientOnly>
          <ChatButton />
        </ClientOnly>
      )}
    </ChatContext.Provider>
  );
};

export default ChatProvider;

import Api from '../Api';
import { CompanyDataInSearch } from '../../components/authentication/registration-suggest/types/BusinessSuggest';
import { getStore } from '../../helpers/env';
import { getConfiguration } from '../../helpers/Configuration';

interface AccountData {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  dateOfBirth?: string;
  isCompany: string;
  company: string;
  kvkNumber: string;
  vatId: string;
  streetName: string;
  streetNumber: string;
  additionalStreetInfo?: string;
  postalCode: string;
  city: string;
  country: string;
  isSubscribed: boolean;
}

/**
 * Lowercase and remove spaces in the email
 * @param {string} email - The email address to format
 */
const formatEmail = (email: string) => email.toLowerCase().replace(/\s/g, '');

class AuthService extends Api {
  async registerFull(accountData: AccountData, storeKey: string, locale: string) {
    const { email, dateOfBirth, ...restData } = accountData;
    const { data } = await this.httpClient
      .post('/register', {
        email: formatEmail(email),
        ...(dateOfBirth && { dateOfBirth }),
        ...restData,
        locale: locale.substring(0, 2),
        localeInCountry: locale,
        storeKey,
      }, { withCredentials: true })
      .catch(this.errorHandler);
    return Promise.resolve(data);
  }

  async businessSuggest(searchQuery: string) {
    const { data } = await this.httpClient
      .post('/business-suggest', {
        searchQuery,
        countryCode: getConfiguration().officeAddress.countryCode,
        store: getStore(),
      })
      .catch(this.errorHandler);
    return Promise.resolve(data);
  }

  async businessProfile(companyData: CompanyDataInSearch) {
    const { data } = await this.httpClient
      .post('/business-profile', {
        countryCode: getConfiguration().officeAddress.countryCode,
        ...companyData,
      })
      .catch(this.errorHandler);
    return Promise.resolve(data);
  }
}

export default AuthService;
